<template>
  <div class="notifications-dropdown" v-click-outside="closeDropdown">
    <div
      class="notification-link"
      v-tooltip.bottom="'Notificações'"
      @click="toggleDropdown"
    >
      <div class="omni-pushs bg-danger"></div>
      <span class="material-symbols-outlined notification-icon">notifications</span>
      <div v-if="unreadCount" class="notification-badge">{{ unreadCount }}</div>
    </div>
    <div v-if="dropdownOpen" class="custom-dropdown">
      <div class="dropdown-content">
        <div class="dropdown-header">
          <h4>
            {{ $tc("generic-str.notification", 2)
            }}<span class="material-symbols-outlined icon-header">notifications</span>
          </h4>
          <button class="btn-read" @click="markAllAsRead()" :disabled="allReadChecked">
            {{ $t("generic-str.read-all") }}
          </button>
        </div>
        <div class="notification-list">
          <div v-if="notifications.length === 0" class="no-notifications-message">
            <NoData class="icone-noData" />
            <span>Não há nenhuma notificação!</span>
          </div>
          <div
            v-for="(items, date) in notificationsGrouped"
            :key="date"
            class="notification-date-group"
          >
            <h5>{{ date }}</h5>
            <router-link
              v-for="(notification, index) in items"
              :key="index"
              :to="notification.link || `/notifications/`"
              class="notification-item"
              @click.native="handleNotificationClick(index, date)"
            >
              <div class="notification-content">
                <div class="spans">
                  <span
                    :class="[
                      'notification-icon-type',
                      `type-${notification.type.toLowerCase()}`,
                    ]"
                  >
                    <span
                      v-if="notification.type === 'INFO'"
                      class="material-symbols-outlined"
                      >info</span
                    >
                    <span
                      v-if="notification.type === 'SUCCESS'"
                      class="material-symbols-outlined"
                      >check</span
                    >
                    <span
                      v-if="notification.type === 'TEXT'"
                      class="material-symbols-outlined"
                      >chat</span
                    >
                    <span
                      v-if="notification.type === 'WARNING'"
                      class="material-symbols-outlined"
                      >warning</span
                    >
                    <span
                      v-if="notification.type === 'ALERT'"
                      class="material-symbols-outlined"
                      >notifications</span
                    >
                  </span>
                </div>
                <div class="notification-content-text">
                  <span class="item-title">{{ notification.title }}</span>
                  <p class="item-info">{{ notification.content }}</p>
                  <div class="notification-content-footer">
                    <p class="notification-date">
                      {{ notification.created_at | formatTime }}
                    </p>
                    <span
                      :class="[
                        notification.status ? 'confirmationMsg' : 'noconfirmationMsg',
                      ]"
                      class="material-symbols-outlined"
                      >{{ notification.status ? "done_all" : "done_all" }}</span
                    >
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <button class="btn-delete" @click="cleanNotifications()"><span class="material-symbols-outlined"> delete </span>
          </button>
          <router-link
            to="/notifications"
            class="all-notifications"
            @click.native="closeDropdown"
          >
            <span
              >{{ $t("generic-str.see-all")
              }}<span class="material-symbols-outlined double-arrow"
                >keyboard_double_arrow_right</span
              ></span
            >
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/plugins/axios';
import account from '@/store/account.module';
import moment from 'moment';
import NoData from '../rebranding/animLottie/NoData.vue';

const webApi = axios();

export default {
  name: 'NotificationsDropdown',
  components: {
    NoData,
  },
  data() {
    return {
      notifications: [],
      dropdownOpen: false,
      loadPage: false,
      allReadChecked: false,
    };
  },
  computed: {
    notificationsGrouped() {
      return this.groupBy(this.notifications, 'created_at');
    },
    unreadCount() {
      return this.notifications.filter((notification) => !notification.status).length;
    },
  },
  created() {
    // this.list();

    this.$root.$on('notify:new', this.handleNewNotification);
  },
  beforeDestroy() {
    this.$root.$off('notify:new', this.handleNewNotification);
  },
  methods: {
    async cleanNotifications() {
      try {
        const response = await webApi.delete(`/notifications/${this.$store.state.account.id}/delete`);
        if (response.status === 200) {
          this.notifications = [];
          this.allReadChecked = false;
          this.$toast.show({
            title: 'Sucesso',
            content: 'Todas as notificações foram limpas',
            type: 'success',
          });
        }
      } catch (error) {
        this.$toast.show({
          title: 'Erro',
          content: 'Não foi possível limpar as notificações',
          type: 'danger',
        });
      }
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
    closeDropdown() {
      this.dropdownOpen = false;
    },
    async handleNotificationClick(index, date) {
      const notification = this.notificationsGrouped[date][index];
      await this.updateNotificationStatus(notification.id, true);
      notification.status = true;
      this.closeDropdown();
    },
    async updateNotificationStatus(id, readStatus) {
      try {
        const response = await webApi.put(`/notifications/status/${id}`, {
          status: readStatus ? 1 : 0,
        });
        if (response.status === 200) {
          console.log('Notification status updated:', response);
        }
      } catch (error) {
        console.error('Error updating notification status:', error);
        this.$toast.show({
          title: 'Erro',
          content: 'Erro ao marcar a notificação como lida',
          type: 'danger',
        });
      }
    },
    async markAllAsRead() {
      try {
        const response = await webApi.put(
          `/notifications/status-all/${this.$store.state.account.id}`,
          {
            status: 1,
          },
        );

        if (response.status === 200) {
          this.notifications.forEach((notification) => {
            notification.status = true;
          });
          this.allReadChecked = true;
        }
      } catch (error) {
        console.error('Error updating all notifications status:', error);
        this.$toast.show({
          title: 'Erro',
          content: 'Erro ao marcar todas as notificações como lidas',
          type: 'danger',
        });
      }
    },
    groupBy(xs, key) {
      return xs.reduce((rv, x) => {
        const dateKey = moment(x[key]).format('DD/MM/YYYY');
        (rv[dateKey] = rv[dateKey] || []).push(x);
        return rv;
      }, {});
    },
    list(page = 0) {
      this.loadPage = true;
      webApi
        .get('/notifications')
        .then((response) => {
          this.loadPage = false;
          if (response.data.content.length > 0) {
            this.notifications = response.data.content;
          }
        })
        .catch((error) => {
          this.$toast.show({
            title: 'Erro',
            content: 'Notification error',
            type: 'danger',
          });
          this.loadPage = false;
        });
    },
    handleNewNotification(notification) {
      const audio = new Audio('url-to-your-notification-sound.mp3');
      audio.play();
      this.hasNotification = true;
      this.notifications.unshift(notification);
    },
  },

  filters: {
    formatTime(value) {
      return moment(value).format('DD/MM/YYYY HH:mm');
    },
  },
};
</script>

<style scoped>
.notifications-dropdown {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-link {
  background: var(--clr-yup-purple);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.notification-link:hover {
  background: #9c60f9;
}

.notification-icon {
  color: #fff;
  font-size: 20px;
}

.custom-dropdown {
  position: absolute;
  width: 300px;
  top: 40px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.dropdown-content {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.notification-list {
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.notification-date-group h5 {
  margin: 0;
  padding: 0.5rem;
  background: var(--clr-yup-purple-light);
  color: #fff;
  border-radius: 4px;
}

.notification-item {
  text-decoration: none;
  color: #333;
  display: flex;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.notification-item:hover {
  background-color: #f0f0f0;
}

.notification-content span {
  font-size: 12px;
  font-weight: 600;
  color: var(--clr-yup-purple);
}

.notification-content {
  width: 100%;
  display: flex;
}

.notification-content p {
  font-size: 11.3px;
  font-weight: 400;
  margin: 3px 0;
}

.item-info {
  color: black;
  font-size: 600 !important;
}

.dropdown-footer {
  margin-top: 10px;
  text-align: center;
}

.all-notifications span {
  color: var(--clr-yup-purple);
  font-size: 14px;
  font-weight: 400;
  padding: 6px;
  display: flex;
  align-items: center;
}

.all-notifications span:hover {
  color: var(--clr-yup-purple-hover) !important;
  font-weight: 400;
  padding: 6px;
}

.all-notifications .double-arrow {
  font-size: 18px !important;
}

.dropdown-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 5px 5px 0 5px;
  margin: 5px 0 0px 0;
}

.dropdown-header h4 {
  margin: 0 !important;
  font-size: 16px;
  display: flex;
  font-weight: 600;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}

.icon-header {
  font-size: 18px;
  font-weight: 600;
}

.notification-content-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.notification-content-footer .confirmationMsg {
  color: var(--clr-yup-purple);
  font-size: 18px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 0 !important;
  gap: 3px;
}

.notification-content-footer .noconfirmationMsg {
  color: #c9c9c9;
  font-size: 18px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 0 !important;
  gap: 3px;
}

.notification-date {
  color: #aaaaaa;
  font-size: 10px !important;
}

.form-check {
  margin: 0;
}

.checkbox {
  display: flex;
  vertical-align: middle;
  padding: 0.5rem;
  justify-content: end;
}
.checkbox input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.checkbox label {
  font-size: 14px !important;
  position: relative;
  padding-left: 1.5em;
  display: inline-block;
  margin: 0;
}
.checkbox label::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  height: 1em;
  width: 1em;
  background: #fff;
  border: 1px solid #999;
  border-radius: 3px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
.checkbox label::after {
  font-family: "Material Icons";
  content: "\e5ca";
  position: absolute;
  top: 50%;
  left: 0.5em;
  transform: translate(-50%, -50%);
  font-size: 1em;
  color: #fff;
  opacity: 0;
  transition: all 0.2s ease;
}
.checkbox label:focus::before,
.checkbox label:hover::before {
  background: #ddd;
}
.checkbox input:checked + label::before {
  background: #6b23dc;
  border-color: #6b23dc;
  border-radius: 3px;
}
.checkbox input:checked + label::after {
  opacity: 1;
}

.notification-icon-type {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
}

.notification-icon-type.type-info {
  background-color: #9597d9;
  color: #fff;
}

.notification-icon-type.type-success {
  background-color: #32b757;
  color: #fff;
}

.notification-icon-type.type-text {
  background-color: #688afd;
  color: #fff;
}

.notification-icon-type.type-warning {
  background-color: #f3b033;
  color: #fff;
}

.notification-icon-type.type-alert {
  background-color: #f34343;
  color: #fff;
}

.notification-content-text {
  width: 100%;
}

.list-notify-dates {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-notify-dates h5 {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin: 0;
  padding: 10px 0 5px 0;
}

.list-notify-dates li {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e1e1e1;
}

.notification-date {
  font-size: 12px;
  color: #777;
}

.notification-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.notification-date-group h5 {
  color: var(--clr-yup-purple);
  width: 100px !important;
  margin: 0.5rem 0;
  font-weight: 600;
}

.spans {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spans span {
  color: #fff !important;
  font-size: 20px !important;
}

.btn-read {
  background-color: var(--clr-yup-purple);
  border: 1px solid var(--clr-yup-purple);
  border-radius: 8px;
  color: #fff;
  padding: 0.5rem;
}

.btn-read:hover {
  background-color: var(--clr-yup-purple-hover);
  border: 1px solid var(--clr-yup-purple);
  border-radius: 8px;
  color: #fff;
  padding: 0.5rem;
}

.notification-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  background: #ffc100;
  color: black;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: bold;
}

.btn-delete {
  font-size: 14px;
  color: red;
  padding: 0px;
  display: flex;
  align-items: center;
  border: none;
  justify-content: center;
  background-color: transparent;
}

.btn-delete:hover {
 color: rgb(192, 2, 2);
}

.no-notifications-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  flex-direction: column;
}

.icone-noData {
  width: 150px;
  height: auto;
  margin: 0 !important;
}
</style>
