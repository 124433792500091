<template>
  <div class="avatar" :class="classObject">
    <img v-if="image" :src="image" alt="user" class="rounded-circle">
    <div v-else class="avatar-name">
      <span>{{ initials }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AvatarComponent',
  props: {
    image: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
      required: true,
    },
    size: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  computed: {
    initials() {
      let [f, l] = this.name.replace(/\s(do|de|da)\s/gi, ' ').split(/\s+/, 2);

      f = `${f || ' '}`.toUpperCase();
      l = `${l || ' '}`.toUpperCase();

      return `${f[0]}${l[0]}`;
    },
    classObject() {
      return [
        this.size,
      ];
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.avatar {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50% !important;
  height: 2.5rem;
  width: 2.5rem;
  &.lg {
    height: 6rem;
    width: 6rem;
    .avatar-name {
      font-size: 3.1578947368rem;
      line-height: 2;
    }
  }

  img {
    width: 100%;
  }

  .avatar-name {
    width: 100%;
    height: 100%;
    text-align: center;
    background: #F2F4F7;
    border-radius: 50% !important;
    font-size: 1.3157894737rem;
    line-height: 1.8;

    span {
      color: var(--clr-yup-purple);
      font-weight: 900;
    }
  }
}
</style>
