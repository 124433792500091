<template>
  <DropdownComponent>
    <template #button>
      {{ $store.state.account.name }}
    </template>
    <template v-for="account in accounts">
      <a :key="account.id" :class="{disabled: account.status != 'active'}" class="dropdown-item"
         href="javascript:void(0)"
         @click="switchAccount(account.id)">
        {{ account.name }}<BadgeComponent class="ml-1" v-if="account.type === 'trial'" variant="warning">{{ $t('app.trial') }}</BadgeComponent>
      </a>
    </template>
    <hr class="dropdown-divider" role="separator"/>
    <router-link class="dropdown-item" to="/accounts">
      {{ $t('acc-settings.view-all') }}
    </router-link>
    <router-link class="dropdown-item" to="/accounts/create">
      {{ $t('acc-settings.create.new-2') }}
    </router-link>
  </DropdownComponent>
</template>

<script>
import AccountService from '@/services/account.service';
import Dropdown from '@/directives/Dropdown';
import DropdownComponent from '@/components/DropdownComponent.vue';
import BadgeComponent from '@/components/ui/BadgeComponent.vue';

export default {
  name: 'Toolbar',
  components: { BadgeComponent, DropdownComponent },
  directives: {
    Dropdown,
  },
  data() {
    return {
      accounts: [],
      selectedAccount: '',
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    switchAccount(accountId) {
      const load = document.createElement('div');
      load.className = 'qt-block-ui';
      document.body.appendChild(load);

      this.$store
        .dispatch('account/select', accountId)
        .then(
          () => {
            if (this.$router.currentRoute.path !== '/') {
              this.$router.push('/');
            } else {
              this.$router.go();
            }
            this.$root.$emit('change.account', accountId);
            this.$root.$emit('checkClient');
          },
          (error) => {
            console.log(error);
          },
        )
        .finally(() => {
          load.remove();
        });
    },
    fetch() {
      AccountService.getAccounts().then(
        (response) => {
          this.accounts = response;
          this.loaded = true;
        },
        (error) => {
          this.content = error;
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
#accounts {
  // margin-top: .35em;
  width: 100%;
  // float: right;
}

@media (max-width: 620px) {
  #accounts {
    display: none;
  }
}

#accounts .dropdown::v-deep {
  border-radius: 0;
  width: 100%;
}

#accounts .dropdown::v-deep > button {
  border-radius: 1rem;
  background-color: var(--clr-yup-purple) !important;
  box-shadow: none !important;
  border: none !important;
  color: #617182 !important;
  padding: 2px !important;
  padding-right: 0 !important;
  padding-left: .62rem !important;
  padding-right: .62rem !important;
  width: 100%;
  color: #fff !important;
  height: 30px;

  .badge {
    border-radius: 1rem;
    margin-right: -5px !important;
  }

  & > * {
    vertical-align: middle;
  }

  .material-icons-outlined {
    font-size: 1.3rem;
    margin-right: .5rem;
  }
}

#accounts .dropdown::v-deep .dropdown-menu {
  background-color: var(--background-2);
  overflow: auto;
  overflow-x: hidden;
  max-height: calc(90vh - 62px);
  min-height: 156px;
}

#accounts::v-deep {
  .dropdown.show {
    color: #fff !important;

    & > button:hover {
      color: #fff !important;
    }
  }

  .dropdown > button:hover {
    color: #fff !important;
  }
}

.badge-warning {
  margin-right: 5px;
  margin-left: 5px;
  color: #000;
}

.trimmed-text {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  max-width: 150px;
  vertical-align: middle;
}

.dropdown-item .trimmed-text {
  max-width: 170px;
}
</style>
