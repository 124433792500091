<template>
  <span class="badge" :class="classObject" @click="handleClick">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'BadgeComponent',
  props: {
    variant: {
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {};
  },
  computed: {
    classObject() {
      return [
        this.variant,
        { 'qt-loader qt-loader-mini qt-loader-right': this.loading },
        { 'btn-block': this.isBlock },
      ];
    },
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
.badge {
  &.primary {
    background-color: var(--clr-yup-purple);
  }
  &.secondary {
    background-color: #d7dde9;
    color: #788db4;
  }
  &.light {
    background-color: rgb(216, 222, 235);
    color: rgb(67, 85, 108);
  }
  &.success {
    background-color: #e1fcef;
    color: #14804a;
  }
  &.info {
    background-color: #9cccf8;
    color: #094d8c;
  }
  &.danger {
    background-color: #ffadb9;
    color: #ad001a;
  }
  &.warning {
    background-color: #fff3cd;
    color: #856404;
  }
  &.dark {
    background-color: #343a40;
    color: #fff;
  }
  .material-symbols-outlined {
    font-size: 18px;
    vertical-align: bottom;
  }
}
</style>
