<template>
  <lottie-animation
    ref="anim"
    :animationData="require('@/assets/anim/no-data.json')"
    :loop="false"
    :autoPlay="true"
    :speed="1"
    class="svg_wrapper"
  />
</template>
<script>
import LottieAnimation from 'lottie-web-vue';

export default {
  components: {
    LottieAnimation,
  },
};
</script>
<style scoped lang="scss">
.svg_wrapper {
  width: 186px;
  height: 186px;
  margin: 3rem auto;
}
</style>
